import {
  AccountId,
  OrgId,
  OrgInvoice,
  OrgInvoiceParent,
  OrgRegistration,
  OrgRegistrationTeamSummary,
  OrgSeasonId
} from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches, verifyIdToken } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function reports__server__getOrgCouponUsage(p: {
  orgId: OrgId;
  startDateMS?: number;
  endDateMS?: number;
  page: number;
  numItemsPerPage: number;
}) {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();

  const query = `
select oi.item->>'createdAtMS'                                                    as checkout_date_ms,
       oi.item -> 'appliedCouponCodeDetails' ->> 'orgCouponCodeSnapshot'          as code,
       (oi.item -> 'appliedCouponCodeDetails' ->> 'discountAmountCents')::numeric as discount_cents,
       concat(trim(pb.item -> 'virtualAthleteAccount' ->> 'firstName'), ' ',
              trim(pb.item -> 'virtualAthleteAccount' ->> 'lastName'))            as player,
       t.item ->> 'name'                                                          as team,
       oi.item ->> 'derivedTotalAmountDueCentsIncludingChildrenInvoices' <>
       oi.item ->> 'derivedTotalAmountPaidCentsIncludingChildrenInvoices'         as has_in_progress_payment_plan,
       oi.item                                                                    as org_invoice,
      count(*) over ()                                                            as count
from mirror_orginvoice oi
         join
     mirror_playerbundle pb on
         oi.item ->> 'playerBundleId' = pb.id
         left join
     mirror_orgregistration r on r.item ->> 'orgInvoiceId' = oi.id
         left join mirror_team t on r.item -> 'relevantPackageInfoSnapshot' ->> 'teamIdThatDeterminedPackage' = t.id
where oi.item ->> 'orgId' = $1
  and (oi.item -> 'appliedCouponCodeDetails') is not null
  and ($2 = '0' OR oi.created_at >= string_ms_to_timestamp($2))
  and ($3 = '0' OR oi.created_at <= string_ms_to_timestamp($3))
order by checkout_date_ms desc
limit $4
offset $5`;

  const res = await getAppPgPool().query(query, [
    p.orgId,
    (p.startDateMS || 0).toString(),
    (p.endDateMS || 0).toString(),
    p.numItemsPerPage,
    p.numItemsPerPage * (p.page - 1)
  ]);

  const rows = res.rows.map(
    a =>
      ({
        ...a,
        checkout_date_ms: parseInt(a.checkout_date_ms),
        discount_cents: parseInt(a.discount_cents),
        count: parseInt(a.count)
      } as {
        checkout_date_ms: number;
        code: string;
        discount_cents: number;
        player: string;
        team: string | null;
        has_in_progress_payment_plan: boolean;
        org_invoice: OrgInvoiceParent;
        count: number;
      })
  );

  return {
    itemsToBeRendered: rows,
    totalNumberOfItemsMatchingCriteria: rows[0]?.count || 0
  };

  // SERVER_ONLY_TOGGLE
}

reports__server__getOrgCouponUsage.auth = async (req: any) => {
  await validateToken(req);
};
